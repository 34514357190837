import { Webview } from '@/proto/WebviewProto'
import * as SafeBuffer from 'safe-buffer'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import crypto from 'crypto'
import axios from 'axios'
import { Nation } from '@/types'
import { Timer } from './questionnaireTimer'

const DEFAULT_API = isDev() ? 'https://lb.easycodedev.com' : ''
export function md5(data: any) {
  const md5 = crypto.createHash('md5')
  return md5.update(data).digest('hex')
}

export function encode(protobufData: any): SafeBuffer.Buffer {
  const aes_key = SafeBuffer.Buffer.from('SEWkGIc2RiqcASstuR4kuw==', 'base64')
  const ci = crypto.createCipheriv('aes-128-ecb', aes_key, '')
  const crypted = ci.update(SafeBuffer.Buffer.from(protobufData))
  return SafeBuffer.Buffer.concat([
    SafeBuffer.Buffer.from(crypted),
    SafeBuffer.Buffer.from(ci.final()),
  ])
}

export function decode(protobufData: any): any {
  const aes_key = SafeBuffer.Buffer.from('SEWkGIc2RiqcASstuR4kuw==', 'base64')
  const deci = crypto.createDecipheriv('aes-128-ecb', aes_key, '')
  const decrypted = deci.update(SafeBuffer.Buffer.from(protobufData))
  return SafeBuffer.Buffer.concat([
    SafeBuffer.Buffer.from(decrypted),
    SafeBuffer.Buffer.from(deci.final()),
  ])
}

export function getP(protoData: any, ts: number) {
  const sign_key = SafeBuffer.Buffer.from('ZTY5NDMxZDNkMDY5NGU0Mjg2ODVmZQ==', 'base64')
  const input = SafeBuffer.Buffer.concat([
    SafeBuffer.Buffer.from(protoData),
    SafeBuffer.Buffer.from(ts.toString()),
    sign_key,
  ])
  return md5(input)
}

export function getFullUrl(params: Record<string, any>, path: string) {
  const { url = DEFAULT_API } = getUrlParams()
  const _url = url + path + '?'
  Object.keys(params).reduce((pre, cur) => {
    return `${pre}${cur}=${params[cur]}&`
  }, url)
  return _url.toString()
}

export function getUrlParams(): {
  token: string
  brand_code: string
  userid: string
  aid: string
  code: string
  lan: string
  svn: string
  svc: string // number
  cvc: string //  number
  cvn: string
  chn: string
  pkg: string
  app: string
  url: string
} {
  // 使用es5的方式获取url参数
  const url = window.location.href
  const params: any = {}
  const str = url.split('?').slice(1).join('&')
  if (str) {
    const strs = str.split('&')
    for (let i = 0; i < strs.length; i++) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      params[strs[i].split('=')[0]] = decodeURIComponent(strs[i].split('=')[1])
    }
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return {
    ...params,
  }
}

export function getHeader(ts: number) {
  const { token, brand_code, userid } = getUrlParams()
  if (isDev()) {
    return {
      auth_token: '2fa249fda5fc491d9abcf337b4444b66',
      timestamp: ts,
      brand_code: 'dev',
      user_id: 751253,
    }
  }
  return {
    auth_token: token,
    brand_code,
    user_id: Number(userid),
    timestamp: ts,
  }
}

export function getParams(ts: number): Record<string, any> {
  const { aid, code, lan, svn, svc, cvc, cvn, chn, pkg, app } = getUrlParams()
  if (isDev()) {
    return {
      aid: '0ad8be7a0e29b111cd0b29894ce586ba',
      code: 'br',
      lan: 'en',
      svn: 'i',
      svc: 1,
      cvc: 1,
      cvn: '1.7.9',
      chn: 'website',
      pkg: 'com.int.h5',
      app: 'int_h5',
      ts,
    }
  }
  return {
    aid,
    code,
    lan,
    svn,
    svc: Number(svc),
    cvc: Number(cvc),
    cvn,
    chn,
    pkg,
    app,
    ts,
  }
}

export async function fetchSurvey(survey_conf_id: number, fn: any) {
  const path = '/act/survey/get/'
  const { GetSurveyReq } = Webview
  const ts = Date.now()
  const header = getHeader(ts)
  if (!header.user_id) return
  console.log('header', header)

  const request = GetSurveyReq.fromObject({
    header,
    // survey_conf_id,
  })
  const params = getParams(ts)
  const { url = DEFAULT_API } = getUrlParams()
  params.sign = getP(request.serializeBinary(), ts)

  params.eid = md5(getFullUrl(params, path)) // 经测试，eid随便是什么都可以，但游戏中是这么设置的，那就这么设置吧
  const response = await axios.post(`${url}${path}`, encode(request.serializeBinary()), {
    headers: {
      'Content-Type': 'application/x-protobuf',
    },
    responseType: 'arraybuffer',
    params,
  })
  console.log('params', params)
  if (response.data) {
    const buffer = decode(response.data)
    fn(buffer)
  }
}

export async function submitSurvey(survey_conf_id: number, question_content: string, fn: any) {
  const path = '/act/survey/submit/'
  const { SubmitSurveyReq } = Webview
  const ts = Date.now()
  const header = getHeader(ts)
  if (!header.user_id) return
  const data = {
    header,
    survey_conf_id,
    write_time: Timer.getTime(),
    question_content,
  }
  console.log('Complete data submitted', data)
  const request = SubmitSurveyReq.fromObject(data)
  const params = getParams(ts)
  const { url = DEFAULT_API } = getUrlParams()
  params.sign = getP(request.serializeBinary(), ts)

  params.eid = md5(getFullUrl(params, path)) // 经测试，eid随便是什么都可以，但游戏中是这么设置的，那就这么设置吧

  const response = await axios.post(`${url}${path}`, encode(request.serializeBinary()), {
    headers: {
      'Content-Type': 'application/x-protobuf',
    },
    responseType: 'arraybuffer',
    params,
  })
  if (response.data) {
    const buffer = decode(response.data)
    fn(buffer)
  }
}

export const close = () => {
  window.close()
  // 预览时使用
  window.parent.postMessage(
    {
      type: 'survey-close',
    },
    '*'
  )
}

export const getCode = (): Nation => {
  const { code } = getUrlParams()
  return code as Nation
}

export function isDev() {
  return process.env.NODE_ENV === 'development'
}

export function randomArray(arr: any[]) {
  return arr.sort(() => Math.random() - 0.5)
}

export function getOptionsExceptOther(options: any) {
  // 提取 other 选项，将其放在最后，并且剩余选项乱序
  const other = options.find((item: any) => item.isOther)
  const rest = options.filter((item: any) => !item.isOther)
  const randomRest = randomArray(rest)
  if (other) {
    return [...randomRest, other]
  }
  return randomRest
}

export function isNaN(num: any) {
  return Object.prototype.toString.call(num) === '[object Number]' && num !== num
}
